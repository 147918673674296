.footer-section .social-icon .icon-area .social-footer .icon {
    font-size: 30px;
    /* border: .063rem solid #5a5b8a; */
    /* border-radius: 50%; */
    transition: all .3s ease-in
}

.footer-section .social-icon .icon-area .social-footer .icon:hover {
    color: #fff;
    border: .09rem solid #fa6d1d;
    background: #fa6d1d
}

.social-footer svg {
    background: #202362;
    color: #ffffff;
}

.footer-section {
    background: #2c50ba
}

.footer-section .footer-content .footer-area {
    padding: 7.1rem 0 1.188rem
}

.footer-section .footer-content .footer-area .footer-left .footer-menu {
    display: inline-block;
    padding-left: 0;
    float: left
}

.footer-section .footer-content .footer-area .footer-left .footer-menu .footer-item {
    list-style: none;
    display: inline-block
}

.footer-section .footer-content .footer-area .footer-left .footer-menu .footer-item .footer-link {
    cursor: pointer
}

.footer-section .footer-content .footer-area .footer-right .footer-menu {
    display: inline-block;
    padding-left: 0;
    float: left
}

.footer-section .footer-content .footer-area .footer-right .footer-menu .footer-item {
    list-style: none;
    display: inline-block
}

.footer-section .footer-content .footer-area .footer-right .footer-menu .footer-item .footer-link {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1rem;
    color: #fcfdff;
    font-weight: 600;
    line-height: 1.75;
    margin-left: 2.188rem
}

.footer-section .footer-content .footer-area .footer-right .footer-menu .footer-item .footer-link:hover {
    color: #fa6d1d;
    transition: .3s ease-in-out;
    cursor: pointer
}

.footer-section .footer-content .footer-bottom-area {
    border-top: .063rem solid #343878
}

.footer-section .footer-content .footer-bottom-area .footer-bottom p {
    color: #b7b2b2;
    font-size: 1rem;
    margin: 2.125rem 0
}

.footer-section .footer-content .footer-bottom-area .footer-bottom p a {
    color: #fa6d1d;
    font-size: 1rem;
    margin-left: .313rem;
    text-decoration: none
}

.footer-section .footer-content .footer-bottom-area .social-icon {
    margin: 2.187rem 0 1.25rem
}

.footer-section .footer-content .footer-bottom-area .social-icon .icon-area {
    padding: 0
}

.footer-section .footer-content .footer-bottom-area .social-icon .icon-area .social-footer {
    list-style: none;
    display: inline-block;
    margin-left: .313rem
}
