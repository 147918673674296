.header-section {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    position: absolute
}

.header-section .navbar-collapse .navbar-nav .nav-item {
    padding: .938rem 0;
    margin-right: 2.188rem
}

.header-section .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 0;
    border-bottom: .125rem solid;
    border-color: transparent
}

.header-section .navbar-collapse .navbar-nav .nav-item :hover {
    color: #f06a29;
    border-bottom: .125rem solid #f06a29
}

.header-section .right-side-box .buy-btn {
    text-decoration: none;
    background: #19a8ff;
    color: #fff;
    text-transform: uppercase;
    padding: .813rem 1.875rem;
    border-radius: 1.875rem;
}

.header-section .right-side-box .buy-btn img {
    margin-right: .625rem
}

.header-section .shape-area {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.header-section .shape-area img {
    position: absolute;
    width: 1.563rem;
    z-index: -1;
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate
}

.header-section .shape-area img.star {
    width: 2.188rem;
    -webkit-animation: animationFramesR 5s linear infinite;
    animation: animationFramesR 5s linear infinite
}

.header-section .shape-area .shape-1 {
    top: 8.125rem;
    left: 42%;
    -webkit-animation: mover 2.5s infinite alternate;
    animation: mover 2.5s infinite alternate
}

.header-section .shape-area .shape-2 {
    top: 7.188rem;
    right: 4%;
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 2s infinite alternate
}

.header-section .shape-area .shape-3 {
    top: 16.875rem
}

.header-section .shape-area .shape-3,.header-section .shape-area .shape-4 {
    left: 42%;
    -webkit-animation: mover 1.5s infinite alternate;
    animation: mover 1.5s infinite alternate
}

.header-section .shape-area .shape-4 {
    top: 40.688rem
}

.header-section .shape-area .shape-5 {
    top: 25.625rem;
    left: 4%;
    -webkit-animation: mover 3s infinite alternate;
    animation: mover 3s infinite alternate
}

.header-section .shape-area .shape-6 {
    top: 8.75rem;
    right: 35%;
    -webkit-animation: animationFramesR 3s linear infinite;
    animation: animationFramesR 3s linear infinite
}

.header-section .shape-area .shape-7 {
    top: 8.75rem;
    right: 20%;
    -webkit-animation: animationFramesR 6s linear infinite;
    animation: animationFramesR 6s linear infinite
}

.header-section .shape-area .shape-8 {
    top: 12.5rem;
    left: 49%;
    -webkit-animation: animationFramesR 3s linear infinite;
    animation: animationFramesR 3s linear infinite
}

.header-section .shape-area .shape-9 {
    top: 12.5rem;
    right: 15%;
    -webkit-animation: animationFramesR 2s linear infinite;
    animation: animationFramesR 2s linear infinite
}

.header-section .shape-area .shape-10 {
    top: 17.188rem;
    right: 11%;
    -webkit-animation: animationFramesR 4s linear infinite;
    animation: animationFramesR 4s linear infinite
}

.header-section .shape-area .shape-11 {
    top: 43.625rem;
    right: 15%;
    -webkit-animation: animationFramesR 1s linear infinite;
    animation: animationFramesR 1s linear infinite
}

.mockup {
    position: absolute;
    top: 150px;
}

.banner-section .mockup img {
    width: 880px;
}

.stores-button {
    display: grid;
}