.contact-us-section {
    margin-top: -16.375rem;
    z-index: 2;
    background: #2d64bb
}

.contact-us-section .contact-us-area {
    background-color: #2d64bb;
    -moz-clip-path: circle(87.4% at 50% 101%);
    -ms-clip-path: circle(87.4% at 50% 101%);
    -webkit-clip-path: circle(87.4% at 50% 101%);
    clip-path: circle(87.4% at 50% 101%);
    padding: 7.063rem 0 0
}

.contact-us-section .contact-us-area .contact-us-text .contact-us-title {
    font-size: 24px;
    color: #fa6d1d;
    text-transform: uppercase;
    line-height: 1.5
}

.contact-us-section .contact-us-area .contact-us-text .contact-us-subtitle {
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 10px
}

.contact-us-section .contact-us-area .contact-us-text .contact-us-title-describe {
    font-size: 18px;
    color: #b7b2b2;
    font-weight: 300;
    line-height: 1.5;
    width: 75%;
    margin-left: auto;
    margin-right: auto
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua {
    background-color: #2d64bb;
    padding: 2rem 3.125rem 2.5rem
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua h3 {
    font-size: 1.125rem;
    color: #fff;
    line-height: 1.417
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua ::-webkit-input-placeholder {
    color: #9c9ae0;
    opacity: 1
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua :-ms-input-placeholder {
    opacity: 1
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua ::-ms-input-placeholder {
    opacity: 1
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua ::placeholder {
    color: #9c9ae0;
    opacity: 1
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua :-ms-input-placeholder {
    color: #9c9ae0
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua ::-ms-input-placeholder {
    color: #9c9ae0
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua .contact-frm {
    display: block;
    width: 100%;
    height: 3.125rem;
    margin: 1.25rem 0;
    font-size: 1rem;
    outline: none;
    background: transparent;
    border: none;
    border-bottom: .063rem solid #bbbbbb;
    color: #c5c4e1;
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua .contact-frm:hover {
    border-bottom: .063rem solid #fa6d1d
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua .contact-msg {
    display: block;
    width: 100%;
    height: 6.688rem;
    color: #c5c4e1;
    font-size: 1rem;
    outline: none;
    border: none;
    border-bottom: .063rem solid #bbbbbb;
    margin: 1.875rem 0 1.25rem;
    background: transparent
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua .contact-msg:hover {
    border-bottom: .063rem solid #fa6d1d
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua .btn-submit {
    text-align: center
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua .btn-submit .contact-btn {
    outline: none;
    border: none;
    padding: .813rem 1.75rem;
    border-radius: 1.875rem;
    margin-top: 1.25rem;
    font-size: 1rem;
    color: #fff;
    background: #fa6d1d;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2.125;
    transition: all .3s ease-in
}

.contact-us-section .contact-us-area .contact-form .contact-form-aqua .btn-submit .contact-btn:hover {
    background: #fff;
    color: #fa6d1d
}