@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;600&display=swap");
@import url("https://use.typekit.net/icw8wvm.css");

@font-face {
  font-family: ginbo;
  src: url(/fonts/ginbo.eot);
  src: url(/fonts/ginbod41d.eot?#iefix) format("embedded-opentype"),
    url(/fonts/ginbo.woff) format("woff"),
    url(/fonts/ginbo.ttf) format("truetype"),
    url(/fonts/ginbo.svg#ginbo) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*="ginbo-"]:before {
  display: inline-block;
  font-family: ginbo;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stores-button img {
  width: 220px;
}

body {
  margin: 0;
  background-color: #292b78 !important;
}

body,
html {
  overflow-x: hidden;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.mb-60 {
  margin-bottom: 3.75rem;
}

.mb-60-none {
  margin-bottom: -3.75rem;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes icon-bubble {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }

  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.8;
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.55;
  }

  75% {
    -webkit-transform: scale(1.32);
    transform: scale(1.32);
    opacity: 0.3;
  }

  to {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes icon-bubble {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }

  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.8;
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.55;
  }

  75% {
    -webkit-transform: scale(1.32);
    transform: scale(1.32);
    opacity: 0.3;
  }

  to {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-0.625rem);
    transform: translateY(-0.625rem);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-0.625rem);
    transform: translateY(-0.625rem);
  }
}

@-webkit-keyframes animationFramesR {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  to {
    -webkit-transform: translate(0) rotate(100deg);
    transform: translate(0) rotate(100deg);
  }
}

@keyframes animationFramesR {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }

  to {
    -webkit-transform: translate(0) rotate(100deg);
    transform: translate(0) rotate(100deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  overflow: visible;
  background: #1c1b1b url(/img/preloder.gif) no-repeat 50%;
  background-size: 25rem 25rem;
}

.ginbo-navbar {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: transparent;
  transition: 0.5s;
}

.ginbo-navbar .logo-section .logo-title {
  margin-right: 0.313rem;
}

.ginbo-navbar .nav-main .navbar-nav .nav-item {
  list-style: none;
  float: left;
  margin-right: 1.563rem;
}

.ginbo-navbar .nav-main .navbar-nav .nav-item .nav-link {
  text-decoration: none;
  color: snow;
  font-weight: 600;
}

.ginbo-navbar .nav-main .navbar-nav .nav-item .nav-link:hover {
  color: #f50;
  transition: 0.3s;
}

.ginbo-navbar.sticky {
  position: fixed;
  width: 100%;
  z-index: 9999;
  background: #1c1646;
  display: block;
}

.ginbo-navbar.sticky .right-side-box {
  margin: 0 0.625rem 0 2.813rem;
}

.banner-section {
  background: url(/img/header-bg.png) no-repeat;
  background-size: 50%;
}

.banner-section .overlay {
  padding: 17.4rem 0 12.6rem;
  position: relative;
  z-index: 0;
}

.banner-section .overlay:after {
  background-color: #2c9dff;
  opacity: 0.502;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.banner-section .overlay .banner-text {
  margin-bottom: 2.625rem;
}

.banner-section .overlay .banner-text h2 {
  font-size: 3.75rem;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.333;
}

.banner-section .overlay .banner-text h2 span {
  color: #19e0ff;
}

.banner-section .overlay .banner-text p {
  font-size: 1.5rem;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  margin: 0.813rem 0 3.75rem;
}

.banner-section .overlay .banner-text .buy-btn {
  text-decoration: none;
  padding: 0.813rem 1.875rem;
  color: #fff;
  border-radius: 1.875rem;
  border: 0.063rem solid #18a8ff;
  text-transform: uppercase;
}

.banner-section .overlay .banner-text .buy-btn img {
  margin-right: 0.625rem;
}

.banner-section .overlay .banner-text .buy-btn:hover {
  background: #fa6d1d;
  transition: all 0.3s ease-in-out;
}

.banner-section .overlay .banner-img {
  position: absolute;
  background: url(/img/circle-shape.png) no-repeat 50%;
  background-size: 75%;
  width: 135%;
  height: 135%;
  z-index: 0;
  left: -2.5rem;
  top: -5.3rem;
  -webkit-animation: spin 20s linear infinite;
  animation: spin 20s linear infinite;
}

.banner-section .overlay .banner-img-overlay {
  position: absolute;
}

.banner-section .overlay .banner-img-overlay img {
  -webkit-transform: translate(45%, -9%);
  transform: translate(45%, -9%);
  width: 80%;
}

.about-ginbo-section {
  background: #2d50bb;
}

.about-ginbo-section .about-ginbo-area {
  background-size: 50% 100%;
  margin-left: 1.25rem;
  padding: 6.875rem 0 6.25rem;
}

.about-ginbo-section .about-left {
  position: absolute;
}

.about-ginbo-section .about-left .video-box,
.about-ginbo-section .about-left .video-box .icon-box {
  display: inline-block;
  padding: 0.938rem;
  border-radius: 50%;
}

.about-ginbo-section .about-left .video-box .icon-box {
  background: #e58d68;
}

.about-ginbo-section .about-left .video-box .icon-box a {
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.about-ginbo-section .about-left .video-box .icon-box:before {
  content: "";
  position: absolute;
  top: 8%;
  left: 8%;
  height: 6.25rem;
  width: 6.25rem;
  background: #e58d68;
  border-radius: 50%;
  -webkit-animation: icon-bubble 2s linear infinite forwards;
  animation: icon-bubble 2s linear infinite forwards;
}

.about-ginbo-section .about-left .video-box:before {
  content: "";
  position: absolute;
  top: 0.938rem;
  left: 0.938rem;
  height: 5.625rem;
  width: 5.625rem;
  background: #d0aca7;
  border-radius: 50%;
  -webkit-animation: icon-bubble 1.5s linear infinite forwards;
  animation: icon-bubble 1.5s linear infinite forwards;
}

.about-ginbo-section .about-right {
  width: 44%;
}

.about-ginbo-section .about-right .about-text .about-title {
  font-size: 24px;
  color: #19dfff;
  text-transform: uppercase;
  line-height: 1.5;
}

.about-ginbo-section .about-right .about-text .about-subtitle {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.about-ginbo-section .about-right .about-text .about-description {
  font-size: 18px;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.about-ginbo-section .about-right .check-point {
  margin-top: 1.75rem;
}

.about-ginbo-section .about-right .check-point p {
  font-size: 1.125rem;
  font-weight: 700;
  color: #fff;
}

.about-ginbo-section .about-right .check-point p i {
  margin-right: 1.875rem;
}

.features-section {
  padding: 6.9rem 0 4.175rem;
}

.features-section .features-text {
  text-align: center;
}

.features-section .features-text .features-title {
  font-size: 24px;
  color: #fa6d1d;
  text-transform: uppercase;
  line-height: 1.5;
}

.features-section .features-text .features-subtitle {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.features-section .features-text .features-description {
  font-size: 18px;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.features-section .single-item-top {
  margin: 2.5rem 0 2.875rem;
}

.features-section .single-about-box {
  text-align: center;
  margin-bottom: 3.75rem;
}

.features-section .single-about-box .icon-box-outer {
  border: 0.063rem solid #525392;
  padding: 0.625rem;
  border-radius: 50%;
  display: inline-block;
  background: #373882;
}

.features-section .single-about-box .icon-box-outer .icon-box {
  color: #18a8ff;
  width: 4.438rem;
  height: 4.438rem;
  border-radius: 50%;
  background: #fff;
  transition: all 0.3s ease-in;
}

.features-section .single-about-box .icon-box-outer i {
  font-size: 2.5rem;
  line-height: 2;
}

.features-section .single-about-box h3 {
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.357;
  margin: 2.063rem 0 1.25rem;
}

.features-section .single-about-box p {
  font-size: 1.125rem;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
}

.features-section .single-about-box:hover .icon-box-outer {
  border: 0.063rem solid #18a8ff;
}

.features-section .single-about-box:hover .icon-box-outer .icon-box {
  color: #fff;
  background: #18a8ff;
}

.product-showcase-section {
  background: url(/img/product-showcase-bg.jpg) no-repeat 50%;
  background-size: cover;
  padding: 6.9rem 0 7.5rem;
}

.product-showcase-section .product-showcase-text {
  text-align: center;
}

.product-showcase-section .product-showcase-text .product-showcase-title {
  font-size: 24px;
  color: #fa6d1d;
  text-transform: uppercase;
  line-height: 1.5;
}

.product-showcase-section .product-showcase-text .product-showcase-subtitle {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.product-showcase-section .product-showcase-text .product-showcase-description {
  font-size: 18px;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.product-showcase-section .product-review {
  margin-top: 3.625rem;
}

.product-showcase-section .product-review .product-review-area {
  position: relative;
  float: left;
  margin: 0 auto;
  max-width: 100%;
}

.product-showcase-section .product-review .product-review-area .image-warp img {
  width: 100%;
}

.product-showcase-section .product-review .product-review-area .drag-element {
  position: absolute;
  min-width: 0.063rem;
  min-height: 0.063rem;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element.position {
  top: 45%;
  right: 0;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element.position-2 {
  bottom: 45%;
  left: 23%;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element.position-3 {
  top: 12%;
  left: 33%;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element.position-4 {
  top: 45%;
  right: 31%;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element.position-5 {
  top: 23%;
  right: 30%;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element
  .image-area {
  height: auto;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element
  .image-area
  img {
  position: relative;
  z-index: 1;
  display: block;
  box-sizing: border-box;
  text-align: center;
  -webkit-animation: pulse 1s ease infinite;
  animation: pulse 1s ease infinite;
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element
  .image-area
  h3 {
  font-size: 1.125rem;
  color: #333;
  line-height: 2;
  text-align: center;
  background: #fff;
  position: absolute;
  bottom: 0;
  transition: 0.5s ease;
  opacity: 0;
  margin: 3.75rem -3.125rem;
  padding: 0.625rem 1.125rem;
  border: 0.063rem solid #686868;
  border-radius: 0.625rem;
  box-shadow: 0 0.313rem 0.625rem 0 rgba(49, 53, 153, 0.15);
}

.product-showcase-section
  .product-review
  .product-review-area
  .drag-element
  .image-area:hover
  h3 {
  opacity: 1;
}

.more-features-section {
  background: #313599;
  padding: 0.188rem 0 11.875rem;
}

.more-features-section .nav-pills .nav-item {
  max-height: 18.75rem;
}

.more-features-section .nav-pills .nav-item .nav-link {
  display: inline-block;
  background-color: transparent;
}

.more-features-section .nav-pills .nav-item .nav-link img {
  display: none;
  max-width: 100%;
  width: 100%;
  height: 0%;
  -webkit-transform: translateY(-10%);
  transform: translate(-1%, -3%);
}

.more-features-section .nav-pills .nav-item .nav-link img.img-2 {
  -webkit-transform: translate(-1%, -7%);
  transform: translate(-1%, -7%);
}

.more-features-section .nav-pills .nav-item .nav-link img.img-3 {
  -webkit-transform: translate(-1%, -11%);
  transform: translate(-1%, -11%);
}

.more-features-section .nav-pills .nav-item .nav-link img.img-4 {
  -webkit-transform: translate(-1%, -15%);
  transform: translate(-1%, -15%);
}

.more-features-section .nav-pills .nav-item .nav-link img.img-5 {
  -webkit-transform: translate(-1%, -19%);
  transform: translate(-1%, -19%);
}

.more-features-section .nav-pills .nav-item .nav-link.active img {
  display: block;
}

.more-features-section .scrollspy-example {
  position: relative;
  height: 30rem;
  overflow: auto;
  margin: 10.313rem 0 0;
}

.more-features-section .scrollspy-example .scroll {
  padding-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.more-features-section .scrollspy-example .scroll h2 {
  position: relative;
  z-index: 1;
  margin: 0 0 1.313rem;
  font-size: 3.125rem;
  color: #fff;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
}

.more-features-section .scrollspy-example .scroll p {
  font-size: 1.5rem;
  color: #fa6d1d;
  text-transform: uppercase;
  line-height: 1.5;
  margin: 0 0 0.625rem;
}

.more-features-section .scrollspy-example .scroll .describe {
  font-size: 18px;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
  margin: 0 0 3.688rem;
}

.more-features-section .scrollspy-example .scroll a {
  padding: 0.813rem 1.875rem;
  border-radius: 1.875rem;
  border: 0.063rem solid #fa6d1d;
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
}

.more-features-section .scrollspy-example .scroll a:hover {
  background: #fa6d1d;
  transition: all 0.3s ease-in-out;
}

.more-features-section .scrollspy-example::-webkit-scrollbar {
  display: none;
  scrollbar-color: transparent transparent;
}

.testimonials-section {
  background: #313599;
  padding: 6.9rem 0 6.15rem;
}

.testimonials-section .testimonials-text {
  margin-bottom: 3.438rem;
  text-align: center;
}

.testimonials-section .testimonials-text .testimonials-title {
  font-size: 24px;
  color: #fa6d1d;
  text-transform: uppercase;
  line-height: 1.5;
}

.testimonials-section .testimonials-text .testimonials-subtitle {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.testimonials-section .testimonials-text .testimonials-description {
  font-size: 18px;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.testimonials-section .testimonials-slider-area .single-item .item-comment {
  border: 0.063rem solid #5a5dad;
  background-color: #2d64bb;
  box-shadow: 0.125rem 0.25rem 0.688rem 0.063rem rgba(34, 37, 109, 0.4);
  padding: 0.938rem;
  border-radius: 0.625rem;
  position: relative;
}

.testimonials-section .testimonials-slider-area .single-item .item-comment p {
  font-size: 1.25rem;
  font-weight: 300;
  color: #b7b2b2;
  line-height: 1.417;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .item-comment:after {
  top: 98%;
  left: 15%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  margin-left: -1.875rem;
  bottom: -1.25rem;
  border: solid rgba(136, 183, 213, 0);
  border-top: solid #313599;
  border-width: 1.25rem 2.188rem 1.875rem 0.625rem;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .item-comment:before {
  top: 100%;
  left: 15%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-left: 2rem solid rgba(194, 225, 245, 0);
  border-right: 2rem solid rgba(194, 225, 245, 0);
  border-top: 2rem solid #5a5dad;
  margin-left: -2rem;
  bottom: -1.25rem;
  border-color: #5a5dad rgba(136, 183, 213, 0) rgba(194, 225, 245, 0);
  border-style: solid;
  border-width: 1.25rem 2.188rem 2rem 0.625rem;
}

.testimonials-section .testimonials-slider-area .single-item .client-info {
  padding-top: 1.875rem;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .client-info
  .client-img-area {
  border: 0.063rem solid #595cad;
  float: left;
  background: #3e41a0;
  padding: 0.438rem;
  border-radius: 50%;
  width: 22%;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .client-info
  .client-img-area
  .client-img {
  background: #3e41a0;
  padding: 0;
  border-radius: 50%;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .client-info
  .client-img-area
  .client-img
  img {
  display: block;
  width: 100%;
  border-radius: 100%;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .client-info
  .client-details {
  padding-left: 6.75rem;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .client-info
  .client-details
  h5 {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fa6d1d;
  line-height: 1.417;
}

.testimonials-section
  .testimonials-slider-area
  .single-item
  .client-info
  .client-details
  p {
  font-size: 1.125rem;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.889;
}

.testimonials-section .testimonials-slider-area.slick-initialized .slick-prev {
  left: -1%;
  top: 25%;
  position: absolute;
  bottom: -0.625rem;
  font-size: 1.25rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0.688rem 1.375rem 0 rgba(35, 35, 35, 0.08);
  border: none;
  height: 3.125rem;
  width: 3.125rem;
  padding: 0;
  line-height: 3.125rem;
  outline: none;
  z-index: 1;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .slick-prev:hover {
  background-color: #fa6d1d;
  transition: 0.5s;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .slick-prev
  i {
  color: #d4d8e3;
  font-weight: 700;
}

.testimonials-section .testimonials-slider-area.slick-initialized .slick-next {
  right: -1%;
  top: 25%;
  position: absolute;
  bottom: -0.625rem;
  font-size: 1.25rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0.688rem 1.375rem 0 rgba(35, 35, 35, 0.08);
  border: none;
  height: 3.125rem;
  width: 3.125rem;
  padding: 0;
  line-height: 3.125rem;
  outline: none;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .slick-next:hover {
  background-color: #e15218;
  transition: 0.5s;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .slick-next
  i {
  color: #d4d8e3;
  font-weight: 700;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .testimonials-slider-dots {
  padding-left: 0;
  text-align: center;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .testimonials-slider-dots
  li {
  margin: 4.375rem 0 0 0.5em;
  display: inline-block;
  list-style: none;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .testimonials-slider-dots
  li:first-child {
  margin-left: 0;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .testimonials-slider-dots
  .dot {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  text-decoration: none;
  background: #c7c9e5;
  border-radius: 50%;
  cursor: pointer;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .testimonials-slider-dots
  .dot
  .string {
  position: absolute;
  left: -6249.938rem;
  line-height: 0;
  opacity: 0;
}

.testimonials-section
  .testimonials-slider-area.slick-initialized
  .testimonials-slider-dots
  .slick-active
  .dot {
  background: #fa6d1d;
}

.faqs-section {
  padding: 6.9rem 0 5.363rem;
}

.faqs-section .faqs-text {
  text-align: center;
  margin-bottom: 3.438rem;
}

.faqs-section .faqs-text .faqs-title {
  font-size: 24px;
  color: #fa6d1d;
  text-transform: uppercase;
  line-height: 1.5;
}

.faqs-section .faqs-text .faqs-subtitle {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.faqs-section .faqs-text .faqs-description {
  font-size: 18px;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.faqs-section .faqs-accordion .faqs-card {
  border-radius: 0.625rem;
  box-shadow: 0.063rem 0.125rem 0.688rem 0.063rem rgba(26, 28, 100, 0.2);
  margin-bottom: 1.875rem;
  background: transparent url(/img/faqs-bg.png) 50%;
}

.faqs-section .faqs-accordion .faqs-card .faqs-card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border: none;
}

.faqs-section .faqs-accordion .faqs-card .faqs-card-header .faqs-btn-link {
  font-size: 1.25rem;
  text-decoration: none;
  color: #fa6d1d;
  padding: 1.125rem 3.125rem 1.25rem 0.938rem;
  width: 100%;
  text-align: left;
}

.faqs-section
  .faqs-accordion
  .faqs-card
  .faqs-card-header
  .faqs-btn-link.collapsed {
  color: #fff;
  background: #2d2f8b;
}

.faqs-section
  .faqs-accordion
  .faqs-card
  .faqs-card-header
  .faqs-btn-link:before {
  content: "";
  background: transparent url(/img/up.png) 50% no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2.188rem;
  right: 1.25rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.faqs-section .faqs-accordion .faqs-card .faqs-card-header .collapsed:before {
  background: transparent url(/img/down.png) 50% no-repeat;
  top: 2.188rem;
}

.faqs-section .faqs-accordion .faqs-card .faqs-show .faqs-card-body {
  font-size: 1.125rem;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.417;
  border-top: 0.062rem solid #53548e;
}

.faqs-section .faqs-right-area img {
  -webkit-transform: translateY(-2%);
  transform: translateY(-2%);
}

.subscribe-section {
  padding: 6.9rem 0 7.5rem;
  background: #383c97;
}

.subscribe-section .subscribe-text {
  text-align: center;
  margin-bottom: 3rem;
}

.subscribe-section .subscribe-text .subscribe-title {
  font-size: 24px;
  color: #fa6d1d;
  text-transform: uppercase;
  line-height: 1.5;
}

.subscribe-section .subscribe-text .subscribe-subtitle {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.subscribe-section .subscribe {
  font-size: 1rem;
  font-weight: 400;
  color: #9e9ece;
  border: 0.063rem solid #9e9ece;
  border-radius: 3.125rem;
}

.subscribe-section .subscribe .input-subscribe {
  height: 1.75rem;
  font-weight: 400;
  border: none;
  outline: none;
  color: #fafafe;
  border-radius: 1.875rem;
  background: none;
  width: 60%;
  padding: 2.5rem 1.875rem;
}

.subscribe-section .subscribe .input-subscribe::-webkit-input-placeholder {
  color: #c0bdbc;
}

.subscribe-section .subscribe .input-subscribe:-ms-input-placeholder {
  color: #c0bdbc;
}

.subscribe-section .subscribe .input-subscribe::-ms-input-placeholder {
  color: #c0bdbc;
}

.subscribe-section .subscribe .input-subscribe::placeholder {
  color: #c0bdbc;
}

.subscribe-section .subscribe .subscribe-btn {
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 1rem;
  border-radius: 1.875rem;
  padding: 1.25rem 2.5rem;
  background: #fa6d1d;
  float: right;
  border: none;
  margin: 0.5rem 0.625rem;
  transition: all 0.3s ease-in;
}

.subscribe-section .subscribe .subscribe-btn:hover {
  color: #fa6d1d;
  background: #fff;
  transition: all 0.3s ease-in;
}

.blog-section {
  position: relative;
  z-index: 0;
  background: #292b78;
  padding: 6.8rem 0 26.7rem;
}

.blog-section .blog-text {
  text-align: center;
  margin-bottom: 3.438rem;
}

.blog-section .blog-text .blog-title {
  font-size: 24px;
  color: #fa6d1d;
  text-transform: uppercase;
  line-height: 1.5;
}

.blog-section .blog-text .blog-subtitle {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.blog-section .blog-text .blog-description {
  font-size: 18px;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.blog-section .item-single .img-block .date-area {
  position: absolute;
  background-size: 100%;
  cursor: pointer;
  line-height: 2;
  transition: line-height 0.5s;
}

.blog-section .item-single .img-block .date-area span {
  font-size: 1.063rem;
  font-weight: 300;
  background: #fa6d1d;
  padding: 0.313rem 0.625rem;
  color: #fff;
  border-radius: 0.188rem;
}

.blog-section .item-single .img-block .image-area img {
  width: 100%;
  border-radius: 0.313rem;
}

.blog-section .item-single .info-block .name {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.286;
  margin: 1.25rem 0;
}

.blog-section .item-single .info-block .name a {
  text-decoration: none;
  color: #fff;
}

.blog-section .item-single .info-block .like-comment-block .like-comment a {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b7b2b2;
  line-height: 1.75;
  text-decoration: none;
  display: inline-block;
}

.blog-section
  .item-single
  .info-block
  .like-comment-block
  .like-comment
  a.border-right {
  border-right: 0.063rem solid #888ab9;
  padding: 0 0.938rem 0 0;
  margin-right: 0.938rem;
}

.blog-section .item-single .info-block .like-comment-block .like-comment a i {
  color: #fa6d1d;
  font-size: 1rem;
  margin-right: 0.625rem;
}

.blog-section .item-single .info-block h4 {
  font-size: 1.125rem;
  color: #b7b2b2;
  font-weight: 300;
  line-height: 1.5;
  margin: 1.375rem 0 1.875rem;
}

.blog-section .item-single .info-block .border-area {
  border: 0.063rem solid #fff;
  width: 100%;
  opacity: 0.1;
  margin-bottom: 1.25rem;
}

.blog-section .item-single .info-block .read-more {
  font-size: 1rem;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  line-height: 2.125;
  letter-spacing: 0.313rem;
}

.blog-section .item-single .info-block .read-more:hover {
  color: #fa6d1d;
  transition: all 0.3s ease-in;
}

@media (min-width: 320px) and (max-width: 575px) {
  .justify-content-xm-center {
    justify-content: center !important;
  }

  .shape-section {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .more-features-section .scrollspy-example {
    margin: 0 !important;
  }
}

@media (min-width: 1440px) and (max-width: 1949px) {
  .about-ginbo-section .about-right {
    width: 75%;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .about-ginbo-section .about-right {
    width: 85%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  :root {
    font-size: 0.75rem;
  }

  .header-section .shape-area .shape-9 {
    top: 12.5rem;
    right: 10%;
  }

  .header-section .shape-area .shape-10 {
    right: 5%;
  }

  .header-section .shape-area .shape-11 {
    right: 3%;
    top: 38.625rem;
  }

  .banner-section .shape-area .shape-11 {
    top: 30.625rem;
  }

  .banner-section .overlay {
    padding: 12.5rem 0 9.375rem;
  }

  .banner-section .overlay .banner-img-overlay img {
    -webkit-transform: translate(75%, 1%);
    transform: translate(75%, 1%);
    width: 60%;
  }

  .banner-section .overlay .banner-img {
    background-size: 55%;
    left: -2.083rem;
    top: -4.166rem;
  }

  .about-ginbo-section .about-left .video-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box:before {
    top: -5%;
    left: -5%;
  }

  .about-ginbo-section .about-left .video-box .icon-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box .icon-box:before {
    top: -15%;
    left: -15%;
  }

  .about-ginbo-section .about-right {
    width: 100%;
  }

  .about-ginbo-section .about-right .check-point p i {
    margin-right: 0.875rem;
  }

  .world-map-section .map-area .drag-element.position-1 {
    top: 15%;
    left: 43%;
  }

  .world-map-section .map-area .drag-element.position-2 {
    top: 19%;
    left: 32%;
  }

  .world-map-section .map-area .drag-element.position-3 {
    top: 34%;
    left: 32%;
  }

  .world-map-section .map-area .drag-element.position-4 {
    bottom: 30%;
    left: 38%;
  }

  .world-map-section .map-area .drag-element.position-5 {
    bottom: 43%;
    left: 48%;
  }

  .world-map-section .map-area .drag-element.position-6 {
    top: 16%;
    right: 33%;
  }

  .world-map-section .map-area .drag-element.position-7 {
    top: 33%;
    right: 40%;
  }

  .world-map-section .map-area .drag-element.position-8 {
    bottom: 49%;
    right: 35%;
  }

  .world-map-section .map-area .drag-element.position-9 {
    bottom: 20%;
    right: 29%;
  }

  .more-features-section {
    padding: 0.188rem 0 15.875rem;
  }

  .faqs-section .faqs-right-area img {
    width: 150%;
  }

  .contact-us-section {
    margin-top: -17.375rem;
  }

  .contact-us-section .contact-us-area {
    padding: 10.188rem 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  :root {
    font-size: 0.75rem;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .ginbo-navbar {
    background: #1c1646;
    padding: 1.25rem 0;
  }

  .ginbo-navbar i {
    color: #f50;
  }

  .ginbo-navbar .navbar-toggler {
    margin-right: 11.25rem;
  }

  .ginbo-navbar .right-side-box {
    margin: 0.713rem 0.625rem 0 2.813rem;
    position: absolute;
    top: 1.063rem;
    right: 1.25rem;
  }

  .ginbo-navbar.sticky {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: #1c1646;
  }

  .ginbo-navbar.sticky .right-side-box {
    margin: 0.713rem 0.625rem 0 2.813rem;
  }

  .header-section .shape-area .shape-9 {
    right: 10%;
  }

  .header-section .shape-area .shape-10 {
    right: 5%;
  }

  .header-section .shape-area .shape-11 {
    top: 39.625rem;
    right: 5%;
  }

  .banner-section .overlay {
    padding: 12.5rem 0 7.5rem;
  }

  .banner-section .overlay .banner-img-offer {
    left: 20%;
    top: 0;
  }

  .banner-section .overlay .banner-img-overlay img {
    -webkit-transform: translate(33%, -5%);
    transform: translate(33%, -5%);
    width: 80%;
  }

  .banner-section .overlay .banner-text h2 {
    font-size: 3.333rem;
  }

  .banner-section .overlay .banner-img {
    top: -6rem;
  }

  .about-ginbo-section .about-left .video-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box:before {
    top: -5%;
    left: -5%;
  }

  .about-ginbo-section .about-left .video-box .icon-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box .icon-box:before {
    top: -15%;
    left: -15%;
  }

  .about-ginbo-section .about-right {
    width: 100%;
  }

  .about-ginbo-section .about-right .about-text .about-subtitle {
    font-size: 3.333rem;
  }

  .about-ginbo-section .about-right .check-point p i {
    margin-right: 0.875rem;
  }

  .features-section .features-text .features-subtitle {
    font-size: 3.333rem;
  }

  .features-section .single-about-box h3 {
    font-size: 1.6rem;
  }

  .product-showcase-section .product-showcase-text .product-showcase-subtitle,
  .world-map-section .world-map-text .world-map-subtitle {
    font-size: 3.333rem;
  }

  .world-map-section .map-area .drag-element.position-1 {
    top: 16%;
    left: 40%;
  }

  .world-map-section .map-area .drag-element.position-2 {
    top: 18%;
    left: 25%;
  }

  .world-map-section .map-area .drag-element.position-3 {
    top: 36%;
    left: 27%;
  }

  .world-map-section .map-area .drag-element.position-4 {
    bottom: 30%;
    left: 34%;
  }

  .world-map-section .map-area .drag-element.position-5 {
    bottom: 41%;
    left: 48%;
  }

  .world-map-section .map-area .drag-element.position-6 {
    top: 20%;
    right: 28%;
  }

  .world-map-section .map-area .drag-element.position-7 {
    top: 32%;
    right: 34%;
  }

  .world-map-section .map-area .drag-element.position-8 {
    bottom: 51%;
    right: 28%;
  }

  .world-map-section .map-area .drag-element.position-9 {
    bottom: 20%;
    right: 22%;
  }

  .world-map-section .single-item .client-info .icon-box-area .icon-box i {
    padding: 0 0.388rem;
    line-height: 1.5;
  }

  .world-map-section .single-item .client-info .client-details h5 {
    font-size: 1.4rem;
  }

  .world-map-section .single-item.mt-30 {
    margin-top: 2.5rem !important;
  }

  .more-features-section {
    padding: 0.188rem 0 5.625rem;
  }

  .more-features-section .scrollspy-example .scroll h2 {
    font-size: 3.333rem;
  }

  .testimonials-section
    .testimonials-slider-area
    .single-item
    .client-info
    .client-img-area {
    width: 4.75rem;
  }

  .faqs-section .faqs-text .faqs-subtitle,
  .products-section .products-text .products-subtitle,
  .testimonials-section .testimonials-text .testimonials-subtitle {
    font-size: 3.333rem;
  }

  .faqs-section .faqs-right-area img {
    width: 150%;
  }

  .faqs-section .faqs-accordion .faqs-card .faqs-card-header .faqs-btn-link {
    font-size: 1.3rem;
  }

  .subscribe-section .subscribe-text .subscribe-subtitle {
    font-size: 3.333rem;
  }

  .blog-section {
    padding: 7.063rem 0 21rem;
  }

  .blog-section .blog-text .blog-subtitle {
    font-size: 3.333rem;
  }

  .blog-section .item-single .info-block .name {
    font-size: 1.417rem;
    line-height: 1.6;
  }

  .blog-section .item-single .info-block h4 {
    font-size: 1.3rem;
  }

  .contact-us-section {
    margin-top: -10.375rem;
  }

  .contact-us-section .contact-us-area {
    padding: 7.188rem 0 0;
    -webkit-clip-path: circle(103.4% at 50% 101%);
    clip-path: circle(103.4% at 50% 101%);
  }

  .contact-us-section .contact-us-area .contact-us-text .contact-us-subtitle {
    font-size: 3.333rem;
  }

  .footer-section .footer-content .footer-area .social-icon .icon-area {
    padding: 0;
  }

  .footer-section
    .footer-content
    .footer-area
    .footer-left
    .footer-menu
    .footer-item
    .footer-link {
    margin-right: 0.688rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .justify-content-sm-center {
    justify-content: center !important;
  }

  :root {
    font-size: 0.75rem;
  }

  .header-section .shape-area .shape-4 {
    top: 28.688rem;
  }

  .header-section .shape-area .shape-9 {
    top: 18.5rem;
  }

  .header-section .shape-area .shape-8 {
    top: 6.5rem;
    left: 10%;
  }

  .header-section .shape-area .shape-11 {
    display: none;
  }

  .banner-section .overlay .banner-text h2 {
    font-size: 3.333rem;
  }

  .banner-section .banner-right {
    display: none;
  }

  .ginbo-navbar {
    background: #1c1646;
    padding: 1.25rem 0;
  }

  .ginbo-navbar i {
    color: #f50;
  }

  .ginbo-navbar .navbar-toggler {
    margin-right: 11.25rem;
  }

  .ginbo-navbar .right-side-box {
    margin: 0 0 0 2.813rem;
    position: absolute;
    top: 1.875rem;
    right: 1.25rem;
  }

  .ginbo-navbar.sticky {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: #1c1646;
  }

  .about-ginbo-section .about-ginbo-area {
    background-size: 100% 50%;
    padding: 25.875rem 0 6.25rem;
  }

  .about-ginbo-section .about-left {
    top: -10rem;
  }

  .about-ginbo-section .about-left .video-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box:before {
    top: -5%;
    left: -5%;
  }

  .about-ginbo-section .about-left .video-box .icon-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box .icon-box:before {
    top: -15%;
    left: -15%;
  }

  .about-ginbo-section .about-right {
    width: 100%;
    margin-top: 30%;
  }

  .about-ginbo-section .about-right .about-text .about-title {
    font-size: 1.6rem;
  }

  .about-ginbo-section .about-right .about-text .about-subtitle {
    font-size: 3.333rem;
  }

  .about-ginbo-section .about-right .check-point p {
    font-size: 1.4rem;
  }

  .about-ginbo-section .about-right .check-point p i {
    margin-right: 0.875rem;
  }

  .banner-section .overlay {
    padding: 12.5rem 0 6.25rem;
  }

  .banner-section .overlay .banner-img-offer {
    background-size: 70% 50%;
    left: 15%;
    top: 15%;
  }

  .banner-section .overlay .banner-img-overlay img {
    -webkit-transform: translate(65%, 60%);
    transform: translate(65%, 60%);
    width: 60%;
  }

  .features-section .features-text .features-title {
    font-size: 1.6rem;
  }

  .features-section .features-text .features-subtitle {
    font-size: 3.333rem;
  }

  .features-section .single-about-box h3,
  .product-showcase-section .product-showcase-text .product-showcase-title {
    font-size: 1.6rem;
  }

  .product-showcase-section .product-showcase-text .product-showcase-subtitle {
    font-size: 3.333rem;
  }

  .more-features-section {
    padding: 0.188rem 0 6.875rem;
  }

  .more-features-section .nav-pills .nav-item .nav-link img {
    max-width: 130%;
    width: 125%;
    -webkit-transform: translate(-10%, -11%);
    transform: translate(-10%, 11%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-2 {
    -webkit-transform: translate(-10%, 6%);
    transform: translate(-10%, 6%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-3 {
    -webkit-transform: translate(-10%, 1%);
    transform: translate(-10%, 1%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-4 {
    -webkit-transform: translate(-10%, -5%);
    transform: translate(-10%, -5%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-5 {
    -webkit-transform: translate(-10%, -11%);
    transform: translate(-10%, -11%);
  }

  .more-features-section .scrollspy-example .scroll p {
    font-size: 1.6rem;
  }

  .more-features-section .scrollspy-example .scroll h2 {
    font-size: 3.333rem;
  }

  .products-section .products-text .products-title {
    font-size: 1.6rem;
  }

  .products-section .products-text .products-subtitle {
    font-size: 3.333rem;
  }

  .testimonials-section .testimonials-text .testimonials-title {
    font-size: 1.6rem;
  }

  .testimonials-section .testimonials-text .testimonials-subtitle {
    font-size: 3.333rem;
  }

  .testimonials-section
    .testimonials-slider-area
    .single-item
    .client-info
    .client-img-area {
    width: 25%;
  }

  .faqs-section .faqs-text .faqs-title {
    font-size: 1.6rem;
  }

  .faqs-section .faqs-text .faqs-subtitle {
    font-size: 3.333rem;
  }

  .faqs-section .faqs-right-area img {
    width: 100%;
  }

  .faqs-section .faqs-accordion .faqs-card .faqs-card-header .faqs-btn-link {
    font-size: 1.4rem;
  }

  .subscribe-section .subscribe-text .subscribe-title {
    font-size: 1.6rem;
  }

  .subscribe-section .subscribe-text .subscribe-subtitle {
    font-size: 3.333rem;
  }

  .blog-section {
    padding: 7.063rem 0 11.25rem;
  }

  .blog-section .blog-text .blog-title {
    font-size: 1.6rem;
  }

  .blog-section .blog-text .blog-subtitle {
    font-size: 3.333rem;
  }

  .blog-section .item-single {
    margin-bottom: 5rem;
  }

  .blog-section .item-single .info-block .name {
    font-size: 1.417rem;
    line-height: 1.6;
  }

  .blog-section .item-single .info-block h4 {
    font-size: 1.3rem;
  }

  .contact-us-section {
    margin-top: -6.375rem;
  }

  .contact-us-section .contact-us-text .contact-us-title {
    font-size: 1.6rem;
  }

  .contact-us-section .contact-us-text .contact-us-subtitle {
    font-size: 3.333rem;
  }

  .contact-us-section .contact-us-area {
    padding: 7.188rem 0 0;
    -webkit-clip-path: circle(119.4% at 50% 101%);
    clip-path: circle(119.4% at 50% 101%);
  }

  .contact-us-section .contact-us-area .contact-form .contact-form-aqua {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .contact-us-section .contact-us-text {
    padding: 0 0.938rem;
  }

  .footer-section
    .footer-content
    .footer-area
    .footer-right
    .footer-menu
    .footer-item
    .footer-link {
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  :root {
    font-size: 0.75rem;
  }

  .ginbo-navbar {
    background: #1c1646;
    padding: 1.25rem 0.938rem;
  }

  .ginbo-navbar i {
    color: #f50;
  }

  .ginbo-navbar .navbar-toggler {
    margin-right: 12.25rem;
  }

  .ginbo-navbar .right-side-box {
    margin: 0.825rem 0 0 2.813rem;
    position: absolute;
    top: 1.063rem;
    right: 1.25rem;
  }

  .ginbo-navbar.sticky {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: #1c1646;
  }

  .ginbo-navbar.sticky .right-side-box {
    margin: 0.825rem 0 0 2.813rem;
  }

  .header-section .shape-area .shape-4,
  .header-section .shape-area .shape-8,
  .header-section .shape-area .shape-9,
  .header-section .shape-area .shape-11 {
    display: none;
  }

  .about-ginbo-section .about-ginbo-area {
    background-size: 95% 35%;
    padding: 15.875rem 0 6.25rem;
  }

  .about-ginbo-section .about-left {
    margin: -15% 0;
  }

  .about-ginbo-section .about-left .video-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box:before {
    top: -5%;
    left: -5%;
  }

  .about-ginbo-section .about-left .video-box .icon-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box .icon-box:before {
    top: -15%;
    left: -15%;
  }

  .about-ginbo-section .about-right {
    width: 100%;
    margin-top: 30%;
  }

  .about-ginbo-section .about-right .about-text .about-title {
    font-size: 1.6rem;
  }

  .about-ginbo-section .about-right .about-text .about-subtitle {
    font-size: 3.333rem;
  }

  .about-ginbo-section .about-right .check-point p i {
    margin-right: 0.875rem;
  }

  .banner-section .overlay {
    padding: 12.5rem 0 5.25rem;
  }

  .banner-section .overlay .banner-text {
    margin-bottom: 3.75rem;
  }

  .banner-section .overlay .banner-text h2 {
    font-size: 3.333rem;
  }

  .banner-section .overlay .banner-right {
    display: none;
  }

  .features-section .features-text .features-title {
    font-size: 1.6rem;
  }

  .features-section .features-text .features-subtitle {
    font-size: 3.333rem;
  }

  .features-section .single-about-box {
    padding: 0 3.438rem;
  }

  .features-section .single-about-box h3,
  .product-showcase-section .product-showcase-text .product-showcase-title {
    font-size: 1.6rem;
  }

  .product-showcase-section .product-showcase-text .product-showcase-subtitle {
    font-size: 3.333rem;
  }

  .world-map-section .world-map-text .world-map-title {
    font-size: 1.6rem;
  }

  .world-map-section .world-map-text .world-map-subtitle {
    font-size: 3.333rem;
  }

  .world-map-section .map-area .drag-element.position-1 {
    top: 16%;
    left: 36%;
  }

  .world-map-section .map-area .drag-element.position-2 {
    top: 20%;
    left: 17%;
  }

  .world-map-section .map-area .drag-element.position-3 {
    top: 34%;
    left: 18%;
  }

  .world-map-section .map-area .drag-element.position-4 {
    bottom: 28%;
    left: 28%;
  }

  .world-map-section .map-area .drag-element.position-5 {
    bottom: 42%;
    left: 45%;
  }

  .world-map-section .map-area .drag-element.position-6 {
    top: 18%;
    right: 24%;
  }

  .world-map-section .map-area .drag-element.position-7 {
    top: 30%;
    right: 31%;
  }

  .world-map-section .map-area .drag-element.position-8 {
    bottom: 47%;
    right: 24%;
  }

  .world-map-section .map-area .drag-element.position-9 {
    bottom: 21%;
    right: 9%;
  }

  .world-map-section .single-item {
    margin-bottom: 1.25rem;
  }

  .more-features-section {
    padding: 7.5rem 0;
  }

  .more-features-section .nav-pills .nav-item .nav-link img {
    max-width: 70%;
    -webkit-transform: translate(20%);
    transform: translate(20%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-2 {
    -webkit-transform: translate(20%, -5%);
    transform: translate(20%, -5%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-3 {
    -webkit-transform: translate(20%, -10%);
    transform: translate(20%, -10%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-4 {
    -webkit-transform: translate(20%, -15%);
    transform: translate(20%, -15%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-5 {
    -webkit-transform: translate(20%, -20%);
    transform: translate(20%, -20%);
  }

  .more-features-section .scrollspy-example .scroll {
    margin: 0;
  }

  .more-features-section .scrollspy-example .scroll p {
    font-size: 1.6rem;
  }

  .more-features-section .scrollspy-example .scroll h3 {
    font-size: 3.333rem;
  }

  .products-section .products-section-text .products-section-title {
    font-size: 1.6rem;
  }

  .products-section .products-section-text .products-section-subtitle {
    font-size: 3.333rem;
  }

  .products-section .item-single .shadow-block .img-block .image-area img {
    width: 80%;
    padding: 1.25rem 0 0.625rem 1.75rem;
  }

  .testimonials-section .testimonials-text .testimonials-title {
    font-size: 1.6rem;
  }

  .testimonials-section .testimonials-text .testimonials-subtitle {
    font-size: 3.333rem;
  }

  .testimonials-section
    .testimonials-slider-area
    .single-item
    .client-info
    .client-img-area {
    width: 30%;
  }

  .faqs-section .faqs-text .faqs-title {
    font-size: 1.6rem;
  }

  .faqs-section .faqs-text .faqs-subtitle {
    font-size: 3.333rem;
  }

  .faqs-section .faqs-accordion .faqs-card .faqs-card-header .faqs-btn-link {
    font-size: 1.5rem;
  }

  .faqs-section .faqs-right-area img {
    width: 100%;
  }

  .subscribe-section .subscribe-text .subscribe-title {
    font-size: 1.6rem;
  }

  .subscribe-section .subscribe-text .subscribe-subtitle {
    font-size: 3.333rem;
  }

  .blog-section {
    padding: 7.063rem 0 12.25rem;
  }

  .blog-section .blog-text .blog-title {
    font-size: 1.6rem;
  }

  .blog-section .blog-text .blog-subtitle {
    font-size: 3.333rem;
  }

  .blog-section .item-single {
    margin-bottom: 3.75rem;
  }

  .blog-section .item-single .info-block .name {
    font-size: 1.417rem;
    line-height: 1.6;
  }

  .blog-section .item-single .info-block h4 {
    font-size: 1.3rem;
  }

  .contact-us-section {
    margin-top: -9.375rem;
  }

  .contact-us-section .contact-us-area {
    padding: 10.188rem 0 0;
    -webkit-clip-path: circle(117.4% at 50% 101%);
    clip-path: circle(117.4% at 50% 101%);
  }

  .contact-us-section .contact-us-area .contact-form .contact-form-aqua {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .contact-us-section .contact-us-area .contact-us-text {
    padding: 0 0.938rem;
  }

  .contact-us-section .contact-us-area .contact-us-text .contact-us-title {
    font-size: 1.6rem;
  }

  .contact-us-section .contact-us-area .contact-us-text .contact-us-subtitle {
    font-size: 3.333rem;
  }

  .footer-section
    .footer-content
    .footer-area
    .footer-left
    .footer-menu
    .footer-item
    .footer-link
    img {
    margin-bottom: 1.666rem;
  }

  .footer-section
    .footer-content
    .footer-area
    .footer-right
    .footer-menu
    .footer-item {
    margin: 0.416rem 1.25rem;
  }

  .footer-section .footer-content .footer-bottom-area .footer-bottom p {
    margin: 2.125rem 0 0.833rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  :root {
    font-size: 0.8rem;
  }

  .header-section .navbar-collapse .navbar-nav .nav-item {
    padding: 0.625rem 0;
  }

  .header-section .shape-area .shape-8 {
    top: 9.5rem;
    left: 24%;
  }

  .header-section .shape-area .shape-3 {
    top: 35.875rem;
    left: 60%;
  }

  .header-section .shape-area .shape-4 {
    display: none;
  }

  .header-section .shape-area .shape-10 {
    top: 34.188rem;
  }

  .header-section .shape-area .shape-11 {
    top: 30.625rem;
  }

  .ginbo-navbar {
    background: #1c1646;
    padding: 1.25rem 0.938rem;
  }

  .ginbo-navbar i {
    color: #f50;
  }

  .ginbo-navbar .navbar-toggler {
    margin-right: 11.25rem;
  }

  .ginbo-navbar .right-side-box {
    margin: 0.313rem 0 0 2.813rem;
    position: absolute;
    top: 1.475rem;
    right: 1.25rem;
  }

  .ginbo-navbar .right-side-box .buy-btn {
    padding: 0.813rem 0.875rem;
  }

  .ginbo-navbar.sticky {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: #1c1646;
  }

  .ginbo-navbar.sticky .right-side-box {
    margin: 0.438rem 0 0 2.813rem;
  }

  .ginbo-navbar.sticky .navbar-nav {
    margin-top: 1.25rem;
  }

  .banner-section .overlay {
    padding: 12.5rem 0 6rem;
  }

  .banner-section .overlay .banner-text h2 {
    font-size: 3rem;
  }

  .banner-section .overlay .banner-text p {
    font-size: 1.4rem;
  }

  .banner-section .overlay .banner-right {
    display: none;
  }

  .about-ginbo-section .about-ginbo-area {
    background-size: 95% 35%;
    padding: 16.875rem 0 6.25rem;
  }

  .about-ginbo-section .about-left {
    margin: -20% 0;
  }

  .about-ginbo-section .about-left .video-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box:before {
    top: -0.438rem;
    left: -0.438rem;
  }

  .about-ginbo-section .about-left .video-box .icon-box {
    padding: 0;
  }

  .about-ginbo-section .about-left .video-box .icon-box:before {
    top: -20%;
    left: -20%;
  }

  .about-ginbo-section .about-right {
    width: 100%;
    margin-top: 9.5rem;
  }

  .about-ginbo-section .about-right .about-text .about-title {
    font-size: 1.25rem;
  }

  .about-ginbo-section .about-right .about-text .about-subtitle {
    font-size: 2.375rem;
  }

  .about-ginbo-section .about-right .about-text .about-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .about-ginbo-section .about-right .check-point p {
    font-size: 1.3rem;
  }

  .about-ginbo-section .about-right .check-point p i {
    margin-right: 0.875rem;
  }

  .features-section .features-text .features-title {
    font-size: 1.25rem;
  }

  .features-section .features-text .features-subtitle {
    font-size: 2.375rem;
  }

  .features-section .features-text .features-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .features-section .single-about-box h3 {
    font-size: 1.6rem;
  }

  .product-showcase-section .product-showcase-text .product-showcase-title {
    font-size: 1.25rem;
  }

  .product-showcase-section .product-showcase-text .product-showcase-subtitle {
    font-size: 2.375rem;
  }

  .product-showcase-section
    .product-showcase-text
    .product-showcase-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .product-showcase-section
    .product-review
    .product-review-area
    .drag-element
    .image-area
    img {
    width: 65%;
  }

  .world-map-section .world-map-text {
    z-index: 1;
  }

  .world-map-section .world-map-text .world-map-title {
    font-size: 1.25rem;
  }

  .world-map-section .world-map-text .world-map-subtitle {
    font-size: 2.375rem;
  }

  .world-map-section .world-map-text .world-map-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .world-map-section .map-area {
    margin: -3.846rem 0 -2.692rem;
    z-index: -1;
  }

  .world-map-section .map-area .drag-element .image-area img {
    width: 60%;
  }

  .world-map-section .map-area .drag-element.position-1 {
    top: 28%;
    left: 36%;
  }

  .world-map-section .map-area .drag-element.position-2 {
    top: 31%;
    left: 14%;
  }

  .world-map-section .map-area .drag-element.position-3 {
    top: 37%;
    left: 16%;
  }

  .world-map-section .map-area .drag-element.position-4 {
    bottom: 35%;
    left: 27%;
  }

  .world-map-section .map-area .drag-element.position-5 {
    bottom: 37%;
    left: 51%;
  }

  .world-map-section .map-area .drag-element.position-6 {
    top: 30%;
    right: 16%;
  }

  .world-map-section .map-area .drag-element.position-7 {
    top: 35%;
    right: 30%;
  }

  .world-map-section .map-area .drag-element.position-8 {
    bottom: 48%;
    right: 20%;
  }

  .world-map-section .map-area .drag-element.position-9 {
    bottom: 32%;
    right: 6%;
  }

  .world-map-section .single-item {
    margin-bottom: 1.25rem;
  }

  .more-features-section {
    padding: 7.5rem 0;
  }

  .more-features-section .nav-pills {
    margin-bottom: 25%;
  }

  .more-features-section .nav-pills .nav-item .nav-link img {
    max-width: 100%;
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-2 {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-3 {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-4 {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
  }

  .more-features-section .nav-pills .nav-item .nav-link img.img-5 {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }

  .more-features-section .scrollspy-example {
    margin: 4.313rem 0 0;
  }

  .more-features-section .scrollspy-example .scroll {
    margin: 0;
  }

  .more-features-section .scrollspy-example .scroll p {
    font-size: 1.25rem;
  }

  .more-features-section .scrollspy-example .scroll h2 {
    font-size: 2.375rem;
  }

  .more-features-section .scrollspy-example .scroll .describe {
    font-size: 1.3rem;
  }

  .products-section .products-text .products-title {
    font-size: 1.25rem;
  }

  .products-section .products-text .products-subtitle {
    font-size: 2.375rem;
  }

  .products-section .products-text .products-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .products-section .item-single .shadow-block .img-block .image-area img {
    width: 80%;
    padding: 1.25rem 0 0.625rem 1.75rem;
  }

  .products-section .item-single .shadow-block .info-block .price-block .price {
    font-size: 2.3rem;
  }

  .testimonials-section .testimonials-text .testimonials-title {
    font-size: 1.25rem;
  }

  .testimonials-section .testimonials-text .testimonials-subtitle {
    font-size: 2.375rem;
  }

  .testimonials-section .testimonials-text .testimonials-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .testimonials-section
    .testimonials-slider-area
    .single-item
    .client-info
    .client-details {
    padding: 0.437rem 0 0 7.75rem;
  }

  .testimonials-section
    .testimonials-slider-area
    .single-item
    .client-info
    .client-img-area {
    width: 5.688rem;
  }

  .faqs-section .faqs-text .faqs-title {
    font-size: 1.25rem;
  }

  .faqs-section .faqs-text .faqs-subtitle {
    font-size: 2.375rem;
  }

  .faqs-section .faqs-text .faqs-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .faqs-section .faqs-accordion .faqs-card .faqs-card-header .faqs-btn-link {
    font-size: 1.3rem;
    padding: 1.154rem 0.769rem;
    text-align: left;
  }

  .faqs-section
    .faqs-accordion
    .faqs-card
    .faqs-card-header
    .faqs-btn-link:before {
    width: 1.3rem;
    height: 1.3rem;
    right: 0.769rem;
  }

  .faqs-section .faqs-accordion .faqs-card .faqs-show .faqs-card-body {
    font-size: 1.2rem;
    padding: 0.769rem 0.769rem 1.154rem;
  }

  .faqs-section .faqs-right-area img {
    width: 100%;
  }

  .subscribe-section .subscribe-text .subscribe-title {
    font-size: 1.25rem;
  }

  .subscribe-section .subscribe-text .subscribe-subtitle {
    font-size: 2.375rem;
  }

  .subscribe-section .subscribe-text .subscribe-description {
    font-size: 1.25rem;
    width: 100%;
  }

  .subscribe-section .subscribe .input-subscribe {
    width: 50%;
    padding: 2.5rem 0 2.5rem 1rem;
  }

  .subscribe-section .subscribe .subscribe-btn {
    padding: 1.385rem 1.308rem;
    font-size: 0.846rem;
  }

  .blog-section {
    padding: 7.063rem 0 10.25rem;
  }

  .blog-section .blog-text .blog-title {
    font-size: 1.25rem;
  }

  .blog-section .blog-text .blog-subtitle {
    font-size: 2.375rem;
  }

  .blog-section .blog-text .blog-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .blog-section .item-single {
    margin-bottom: 3.75rem;
  }

  .blog-section .item-single .info-block .name {
    font-size: 1.417rem;
    line-height: 1.6;
  }

  .blog-section .item-single .info-block h4 {
    font-size: 1.3rem;
  }

  .contact-us-section {
    margin-top: -8.375rem;
  }

  .contact-us-section .contact-us-area {
    padding: 8.188rem 0 0;
    -webkit-clip-path: circle(125.4% at 50% 101%);
    clip-path: circle(125.4% at 50% 101%);
  }

  .contact-us-section .contact-us-area .contact-us-text .contact-us-title {
    font-size: 1.25rem;
  }

  .contact-us-section .contact-us-area .contact-us-text .contact-us-subtitle {
    font-size: 2.375rem;
  }

  .contact-us-section
    .contact-us-area
    .contact-us-text
    .contact-us-title-describe {
    font-size: 1.3rem;
    width: 100%;
  }

  .contact-us-section .contact-us-area .contact-form .contact-form-aqua {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .contact-us-section .contact-us-text {
    padding: 0 0.938rem;
  }

  .footer-section
    .footer-content
    .footer-area
    .footer-left
    .footer-menu
    .footer-item
    img {
    margin-bottom: 1.923rem;
  }

  .footer-section
    .footer-content
    .footer-area
    .footer-right
    .footer-menu
    .footer-item {
    margin: 0.769rem 0;
  }

  .footer-section
    .footer-content
    .footer-area
    .footer-right
    .footer-menu
    .footer-item
    .footer-link {
    margin: 1.153rem;
  }

  .footer-section .footer-content .footer-bottom p {
    margin: 2.125rem 0 0 !important;
  }
}

.features-section {
  background: #2d64bb;
}